import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import accounts from './accounts';
import clients from './clients';
import redeemJobs from './redeemJobs';
import otherJobs from './otherJobs';
import account from './account';
import config from './config';
import products from './products';
import purchaseJobs from './purchaseJobs';
import invoiceJobs from './invoiceJobs';
import trackingJobs from './trackingJobs';
import driver from './driver';
import archiveAccounts from './archiveAccounts';
import proxies from './proxies';
import proxy from './proxy';
import engineStatus from './engineStatus';

export default combineReducers({
  auth,
  accounts,
  account,
  clients,
  redeemJobs,
  otherJobs,
  config,
  products,
  purchaseJobs,
  invoiceJobs,
  trackingJobs,
  driver,
  archiveAccounts,
  proxies,
  proxy,
  engineStatus
});
