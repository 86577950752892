import React from 'react'
import { Typography } from 'antd';
import TableExpenses from './TableExpenses';
import TableErrors from './TableErrors';
import TableAvgTime from './TableAvgTime';
import './styles.scss';

const EngineStatus = () => {

  return (
    <>
      <Typography.Title>Engine Status</Typography.Title>
        <div className='JobStatus'>
        <TableExpenses />
        <TableErrors />
        <TableAvgTime />
        </div>
    </>
  )
}

export default EngineStatus
