import React from 'react';
import { useRoutes } from "react-router-dom";
import RedirectUserToMainPage from '../Components/RedirectUserToMainPage';
import RedirectGuestToSignInPage from '../Components/RedirectGuestToSignInPage';
import Accounts from '../Pages/Accounts';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import RedirectPage from '../Pages/RedirectPage';
import RedeemJob from '../Pages/RedeemJob';
import UnorderedListOutlined from '@ant-design/icons/lib/icons/UnorderedListOutlined';
import OtherJob from '../Pages/OtherJob';
import AccountPage from '../Pages/AccountPage';
import ProfileOutlined from '@ant-design/icons/lib/icons/ProfileOutlined';
import Config from '../Pages/Config';
import Driver from "../Pages/Driver";
import { AccountBookOutlined, BookOutlined, GlobalOutlined, ReadOutlined, SettingOutlined } from "@ant-design/icons";
import ArchiveAccounts from "../Pages/ArchiveAccounts";
import Proxies from "../Pages/Proxies";
import ProxyPage from "../Pages/ProxyPage";
import PurchaseJobs from '../Pages/PurchaseJobs';
import AccountAccess from '../Pages/AccountAccess';
import EngineStatus from '../Pages/Status';

function Routes() {
  const element = useRoutes([
    {
      path: '/sign-in',
      exact: true,
      element: <RedirectUserToMainPage />,
    }, {
      path: '/redirect',
      exact: true,
      element: <RedirectPage />,
    },
    {
      path: '/',
      element: <RedirectGuestToSignInPage />,
      children: [
        {
          path: '/',
          exact: true,
          element: <Accounts />,
        }, {
          path: '/redeem-job',
          exact: true,
          element: <RedeemJob />,
        }, {
          path: '/other-job',
          exact: true,
          element: <OtherJob />,
        }, {
          path: '/account/:id',
          exact: true,
          element: <AccountPage />,
        }, {
          path: '/config',
          exact: true,
          element: <Config />,
        }, {
          path: '/drivers',
          exact: true,
          element: <Driver />,
        }, {
          path: '/archive-accounts',
          exact: true,
          element: <ArchiveAccounts />,
        }, {
          path: '/proxies',
          exact: true,
          element: <Proxies />,
        }, {
          path: '/proxies/proxy/:id',
          exact: true,
          element: <ProxyPage />,
        }, {
          path: '/purchase-jobs',
          exact: true,
          element: <PurchaseJobs />,
        },
        {
          path: '/account/access/:id',
          exact: true,
          element: <AccountAccess />
        },
        {
          path: '/engine-status',
          exact: true,
          element: <EngineStatus />
        }
      ],
    },
  ]);
  return element;
}

export const links = [
  {
    href: '/',
    icon: <UserOutlined />,
    title: 'Account',
  }, {
    href: '/redeem-job',
    icon: <ReadOutlined />,
    title: 'Redeem Jobs',
  }, {
    href: '/other-job',
    icon: <UnorderedListOutlined />,
    title: 'Other Jobs',
  }, {
    href: '/purchase-jobs',
    icon: <AccountBookOutlined />,
    title: 'Purchase Jobs',
  }, {
    href: '/drivers',
    icon: <SettingOutlined />,
    title: 'Drivers',
  }, {
    href: '/proxies',
    icon: <GlobalOutlined />,
    title: 'Proxies',
  }, {
    href: '/archive-accounts',
    icon: <BookOutlined />,
    title: 'Archive accounts',
  },
  {
    href: '/config',
    icon: <ProfileOutlined />,
    title: 'Config',
  }
];

export default Routes;
