import React, { useCallback } from 'react';
import b_ from 'b_';
import { Col, DatePicker, Row, Select } from 'antd';
import './styles.scss';
import { momentToUnixRange, unixToDayjs } from '../../../Utils/dates';

const { Option } = Select;

const RangePicker = DatePicker.RangePicker;

const b = b_.lock('Filters');

function Filters({ curFilters, setCurFilters }) {

  const handleChangeFilter = useCallback(
    (dates) => {
      setCurFilters({
        query: '',
        dates: dates.length ? momentToUnixRange(dates) : [null, null],
      });
    },
    [setCurFilters]
  );

  return (
    <>
  <Row gutter={8} style={{marginBottom: '16px'}} >
  <Col >
      <RangePicker showTime
        value={curFilters.dates ? curFilters.dates.map(unixToDayjs) : [null, null]}
        className={b('item')}
        onChange={handleChangeFilter}
      />
    </Col>
  </Row>
</>
  )
}

export default Filters;
