import { getCurrentDates } from '../Utils/dates';
import ApiService from './ApiService';

function handleParamsData(filters, orderPath, params) {
  if (filters && Object.keys(filters).length) {
    for (let key in filters) {
      if (key === 'Dates') {
        params.fromTs = filters[key][0];
        params.toTs = filters[key][1];
      } else {
        params[key] = filters[key].join(',');
      }
    }
  } else {
    params.fromTs = getCurrentDates()[0];
    params.toTs = getCurrentDates()[1];
  }

  if (orderPath) {
    params.order = orderPath.replace('end', '');
  }
}

const EngineStatusService = {
  getStatusList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        if (key === 'Dates') {
          updatedParams.fromTs = filters[key][0];
          updatedParams.toTs = filters[key][1];
        } else {
          updatedParams[key] = filters[key].join(',');
        }
      }
    } else {
      updatedParams.fromTs = getCurrentDates()[0];
      updatedParams.toTs = getCurrentDates()[1];
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/engine_status', updatedParams)
  },

  getStatusJobsErrors([fromTs, toTs]) {
    const from = fromTs === null ? getCurrentDates()[0] : fromTs
    const to = toTs === null ? getCurrentDates()[1] : toTs

    return ApiService.get(`/engine_status/errors?fromTs=${from}&toTs=${to}`)
  },

  getStatusJobsExpenses([fromTs, toTs]) {
    const from = fromTs === null ? getCurrentDates()[0] : fromTs
    const to = toTs === null ? getCurrentDates()[1] : toTs

    return ApiService.get(`/engine_status/expenditures?fromTs=${from}&toTs=${to}`)
  },

  getStatusJobsAvg([fromTs, toTs]) {
    const from = fromTs === null ? getCurrentDates()[0] : fromTs
    const to = toTs === null ? getCurrentDates()[1] : toTs

    return ApiService.get(`/engine_status/jobs_avg?fromTs=${from}&toTs=${to}`)
  },

  getStatusJobsSubscr([fromTs, toTs]) {
    const from = fromTs === null ? getCurrentDates()[0] : fromTs
    const to = toTs === null ? getCurrentDates()[1] : toTs

    return ApiService.get(`/engine_status/subscriptions?fromTs=${from}&toTs=${to}`)
  },

  getEnabledWorkers() {
    return ApiService.get('/engine_status/workers')
  },
};

export default EngineStatusService
