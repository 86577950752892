import { createAction, createReducer } from "@reduxjs/toolkit";
import { frc } from 'front-end-common';
import EngineStatusService from "../Services/EngineStatusService";

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const engineStatusListFetch = createAction('engineStatus/list', (params) => ({
  payload: EngineStatusService.getStatusList(params)
}));

export const engineStatusErrorsFetch = createAction('engineStatus/errors', (params) => ({
  payload: EngineStatusService.getStatusJobsErrors(params)
}));

export const engineStatusExpensesFetch = createAction('engineStatus/expenses', (dates) => ({
  payload: EngineStatusService.getStatusJobsExpenses(dates)
}));

export const engineStatusAvgTimeFetch = createAction('engineStatus/avg_time', (params) => ({
  payload: EngineStatusService.getStatusJobsAvg(params)
}));

const initialState = {
  list: getDefaultState(),
  errors: getDefaultState(),
  expenses: getDefaultState(),
  avgTime: getDefaultState(),
}

export default createReducer(initialState, {
  ...getDefaultHandler(engineStatusListFetch, 'list'),
  ...getDefaultHandler(engineStatusErrorsFetch, 'errors'),
  ...getDefaultHandler(engineStatusExpensesFetch, 'expenses'),
  ...getDefaultHandler(engineStatusAvgTimeFetch, 'avgTime'),
})
