import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import './styles.scss';
import {Table, Typography} from 'antd';
import { engineStatusErrorsFetch } from '../../../Reducers/engineStatus';
import Filters from '../Filters';
import { useDispatch } from 'react-redux';
import { getCurrentDates } from '../../../Utils/dates';
import { CloseSquareOutlined } from '@ant-design/icons';

const b = b_.lock('StatusTable');

function TableErrors() {

  const dispatch = useDispatch();

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [curFilters, setCurFilters] = useState({ dates: getCurrentDates() });

  useEffect(() => {
    setLoading(true)
    dispatch(engineStatusErrorsFetch(curFilters.dates))
    .then((res) => {
      setList(res)
    })
    .catch((e) => {
      console.log(`Error fetching: ${e}`)
    })
    .finally(() => {
      setLoading(false)
    })
}, [curFilters, dispatch]);

  const rowClassName = (record) => {
    if (record.name === 'TOTAL') {
      return 'highlighted-row';
    }
    return '';
  };

  const GetColumns = useCallback(() => {
    return [
        { 
          dataIndex: "name",
          title: "Group",
          align: "center",
          width: 100,
        },
        { 
              title: "Redeem",
              align: "center",
              dataIndex: "redeemErrors",
              render: (errorsCount) => (
                      <div className={b('col')}>
                        {errorsCount}
                      </div>
        )},

        { 
              title: "Purchase",
              align: "center",
              dataIndex: "purchErrors",
              render: (errorsCount) => (
                      <div className={b('col')}>
                        {errorsCount}
                      </div>
        )},
    ]
  }, [list])

  const [columns, setColumns] = useState(GetColumns());
  const [updatedList, setUpdatedList] = useState([]);

  useEffect(() => {
    setColumns(GetColumns())
}, [list, GetColumns])

useEffect(() => {
    const updatedList = 
      list.map((item) => {
        if (item.name !== 'ALL' && item.isCurrency) {
          const updatedItem = { ...item };
          updatedItem.purchErrors = item.purchErrors;
          updatedItem.redeemErrors = item.redeemErrors;
          return updatedItem;
        }
        return item;
      })
    
    setColumns(GetColumns());
    setUpdatedList(updatedList)
      
    const updatedSum = calculateSum(updatedList);
    setUpdatedList((prev) => [...prev, updatedSum]);
}, [list]);

const calculateSum = useCallback((listToUpdate) => {
  const sum = {
    name: 'TOTAL',
    purchErrors: 0,
    redeemErrors: 0,
  };

  listToUpdate.forEach(item => {
    if (item.name !== 'ALL') {
      sum.purchErrors += item.purchErrors;
      sum.redeemErrors += item.redeemErrors;
    }
  });

  return sum;
}, [list]);

const filteredList = updatedList.filter(item => item.name !== 'ALL');


  return (
    <div className='JobsErrorsTable'>
    <Typography.Title level={4}><CloseSquareOutlined /> Jobs Errors</Typography.Title>
    <Filters curFilters={curFilters} setCurFilters={setCurFilters} />
  <Table 
        rowKey="name"
        bordered
        className={b('table')}
        dataSource={filteredList}
        pagination={false}
        columns={columns}
        loading={loading}
        size="small"
        scroll={{ x: "max-content" }}
        rowClassName={rowClassName}
  />
  </div>
  );
}

export default TableErrors;
