import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import './styles.scss';
import {Table, Typography} from 'antd';
import { engineStatusAvgTimeFetch, engineStatusErrorsFetch } from '../../../Reducers/engineStatus';
import Filters from '../Filters';
import { useDispatch } from 'react-redux';
import { getCurrentDates } from '../../../Utils/dates';
import { ClockCircleOutlined } from '@ant-design/icons';

const b = b_.lock('StatusTable');

function getAvgTime(ms) {
  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(ms / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);

  // Return the formatted time as a string
  return `${minutes}m ${seconds}s`;
}

function TableAvgTime() {
  
  const dispatch = useDispatch();

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [curFilters, setCurFilters] = useState({ dates: getCurrentDates() });

  useEffect(() => {
    setLoading(true)
    dispatch(engineStatusAvgTimeFetch(curFilters.dates))
    .then((res) => {
      setList(res)
    })
    .catch((e) => {
      console.log(`Error fetching: ${e}`)
    })
    .finally(() => {
      setLoading(false)
    })
}, [curFilters, dispatch]);

  const rowClassName = (record) => {
    if (record.name === 'AVERAGE') {
      return 'highlighted-row';
    }
    return '';
  };

  const GetColumns = useCallback(() => {
    return [
        { 
          dataIndex: "name",
          title: "Group",
          align: "center",
          width: 100,
        },
          { 
            title: "Redeem",
            align: "center",
            dataIndex: "redeemJobAvg",
            render: (avgTime) => (
                      <div className={b('col')}>
                        <p>{getAvgTime(avgTime)}</p>
                      </div>
                  )},
            { 
              title: "Purchase",
              align: "center",
              dataIndex: "purchJobAvg",
              render: (avgTime) => (
                                <div className={b('col')}>
                                  <p>{getAvgTime(avgTime)}</p>
                                </div>
                  )}
    ]
  }, [list])

  const [columns, setColumns] = useState(GetColumns());
  const [updatedList, setUpdatedList] = useState([]);

  useEffect(() => {
    setColumns(GetColumns())
}, [list, GetColumns])

useEffect(() => {
    const updatedList = 
      list.map((item) => {
        if (item.name !== 'ALL' && item.isCurrency) {
          const updatedItem = { ...item };
          updatedItem.redeemJobAvg = item.redeemJobAvg;
          updatedItem.purchJobAvg = item.purchJobAvg;
          return updatedItem;
        }
        return item;
      })
    
    setColumns(GetColumns());
    setUpdatedList(updatedList)
      
    const updatedSum = calculateSum(updatedList);
    setUpdatedList((prev) => [...prev, updatedSum]);
}, [list]);

const calculateSum = useCallback((listToUpdate) => {
  const avg = {
    name: 'AVERAGE',
    redeemJobAvg: 0,
    purchJobAvg: 0,
  };

  const validItems = listToUpdate.filter(item => item.name !== 'ALL');
  const count = validItems.length;

  validItems.forEach(item => {
    avg.redeemJobAvg += item.redeemJobAvg;
    avg.purchJobAvg += item.purchJobAvg;
  });

  avg.redeemJobAvg = avg.redeemJobAvg / count;
  avg.purchJobAvg = avg.purchJobAvg / count;

  return avg;
}, [list]);

const filteredList = updatedList.filter(item => item.name !== 'ALL');


  return (
    <div className='AvgTimeTable'>
    <Typography.Title level={4}><ClockCircleOutlined /> Average time</Typography.Title>
    <Filters curFilters={curFilters} setCurFilters={setCurFilters} />
    <Table 
        rowKey="name"
        bordered
        className={b('table')}
        dataSource={filteredList}
        pagination={false}
        columns={columns}
        loading={loading}
        size="small"
        scroll={{ x: "max-content" }}
        rowClassName={rowClassName}
  />
  </div>
  );
}

export default TableAvgTime;
